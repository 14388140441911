<template>
  <div class="content_solicitation">
    <simulate-modal v-if="showModalSimulate" @closeModal="closeModal" :emprestimoId="emprestimoId" :acordoId="acordoId"
      :numeroParcela="numeroParcela" />
    <gerar-boleto-modal v-if="showModalBoleto" @closeModal="closeModal" :acordoId="acordoId"
      :emprestimoId="emprestimoId" :numeroParcela="numeroParcela" />
    <a href="#" @click.prevent="$router.push('/tomador/painel')"
      class="self-start text-sm text-gray-800 underline">Voltar</a>
    <div class="w-full lg:px-12">
      <p class="title">Empréstimos</p>
      <div class="w-full overflow-x-auto lg:overflow-x-hidden mt-6">
        <table>
          <div class="backdrop" v-show="loadingPage">
            <div class="fas fa-dollar-sign spin"></div>
          </div>

          <tr v-for="emprestimo in solicitation.emprestimos" :key="emprestimo.id">
            <td @click="emprestimo.showItem = !emprestimo.showItem">
              <span>
                <i :class="`fas fa-${emprestimo.showItem ? 'minus' : 'plus'}`"></i>
              </span>
              {{ emprestimo.valorFinanciado || 0 | money }} em {{ emprestimo.prazo || 0 }}x &emsp;
              <sup>{{ emprestimo.status }}{{(emprestimo.status != 'Quitado' ? " - " + emprestimo.situacao : '')}}</sup>
            </td>
            <transition v-if="emprestimo.showItem" name="fade">
              <div class="mt-4">
                <!-- <p class="title">Detalhes do empréstimo</p> -->
                <div class="info_main">
                  <p class="info_main_title">Detalhes do empréstimo</p>
                  <div class="info">
                    <div class="info_group">
                      <strong>Valor financiado:</strong>
                      <p>{{ emprestimo.valorFinanciado | money }}</p>
                    </div>
                    <div class="info_group">
                      <strong>Valor liberado:</strong>
                      <p>{{ emprestimo.valorLiberado | money }}</p>
                    </div>
                    <div class="info_group">
                      <strong>Taxa:</strong>
                      <p>{{ emprestimo.taxa.toFixed(2) }}%</p>
                    </div>
                    <div class="info_group">
                      <strong>Prazo:</strong>
                      <p>{{ emprestimo.prazo }} meses</p>
                    </div>
                    <div class="info_group">
                      <strong>Parcelas Pagas:</strong>
                      <p>{{ emprestimo.parcelasPagas }}/{{ emprestimo.prazo }}</p>
                    </div>
                    <div class="info_group">
                      <strong>Parcelas Restantes:</strong>
                      <p>{{ emprestimo.parcelasRestantes }}</p>
                    </div>
                    <div class="info_group">
                      <strong>Parcelas em Atraso:</strong>
                      <p>{{ emprestimo.parcelasEmAtraso }}</p>
                    </div>
                  </div>
                </div>

                <div v-for="a in emprestimo.acordos" v-bind:key="a.id">
                  <p @click="toggleAcordo(a.id)" class="subtitle  cursor-pointer">{{ a.mostrar }}
                    <span>
                      <i style="font-size: 0.7em"
                        :class="`fas fa-${(showAcordos.indexOf(a.id) >= 0 ? 'minus' : 'plus')}`"></i>
                    </span>
                    Acordo de {{ a.data | moment('DD/MM/YYYY') }}
                    <span class="info-acordo" v-if="a.valorEntrada > 0">
                      Entrada{{(a.dataPagamentoEntrada ? ` paga em
                      ${($moment(a.dataPagamentoEntrada).format('DD/MM/YYYY'))}` : '')}}: {{ a.valorEntrada | money }}
                      com
                      vencimento em {{ a.vencimentoEntrada | moment('DD/MM/YYYY') }} <br />
                    </span>
                  </p>


                  <div class="hidden lg:block w-full overflow-x-auto lg:overflow-x-hidden my-4 mt-0"
                    v-if="showAcordos.indexOf(a.id) >= 0">
                    <vuetable ref="vuetable" class="solicitation_table" :api-mode="false" :fields="header"
                      :data="a.parcelas" :no-data-template="loading ? '' : 'Selecione um valor'">
                      <template v-slot:prestacao="props" class="flex items-center">{{
                        props.rowData.prestacao |
                          money
                      }}</template>
                      <template v-slot:juros="props" class="flex items-center">{{
                        props.rowData.juros |
                          money
                      }}</template>
                      <template v-slot:amortizacao="props" class="flex items-center">{{
                        props.rowData.amortizacao |
                          money
                      }}</template>
                      <template v-slot:status="props" class="flex items-center">
                        <p :class="props.rowData.status === 'Pago' ? 'text-green-500' : a.status == 'Cancelado' ? 'text-gray-700' :
                        props.rowData.status === 'Em Atraso' ? 'text-yellow-600' : 'text-gray-700'">{{
    a.status ==
      'Cancelado' ? a.status : props.rowData.status
  }}</p>
                      </template>
                      <template v-slot:vencimentoUtil="props" class="flex items-center">{{
                        props.rowData.vencimentoUtil |
                          moment('DD/MM/YYYY')
                      }}</template>
                      <template v-slot:urlPDFUltimoBoletoGerado="props" class="flex items-center">
                        <i v-if="!props.rowData.urlPDFUltimoBoletoGerado"
                          class="fas fa-external-link-alt text-gray-400 cursor-not-allowed"></i>
                        <i v-else-if="props.rowData.status === 'Em Atraso' && props.rowData.tomadorPodeGerarBoleto"
                          class="fas fa-external-link-alt text-secondary cursor-pointer"
                          @click="toogleModalBoleto(props.rowData.numero, emprestimo.id, a.id)"></i>
                        <i v-else-if="props.rowData.status === 'Em Atraso' && !props.rowData.tomadorPodeGerarBoleto"
                          class="fas fa-external-link-alt text-secondary cursor-pointer"
                          @click="openBoleto(props.rowData.urlPDFUltimoBoletoGerado)"></i>
                        <i v-else-if="props.rowData.status === 'A vencer'"
                          class="fas fa-external-link-alt text-secondary cursor-pointer"
                          @click="openBoleto(props.rowData.urlPDFUltimoBoletoGerado)"></i>
                      </template>
                      <template v-slot:simulacao="props" class="flex items-center">
                        <p v-if="props.rowData.status == 'Em Atraso' && props.rowData.tomadorPodeGerarBoleto && props.rowData.urlPDFUltimoBoletoGerado"
                          @click="toogleModalSimulate(props.rowData.numero, emprestimo.id, a.id)"
                          class="text-secondary underline cursor-pointer">Simular</p>
                      </template>
                    </vuetable>
                  </div>
                  <div class="w-full flex flex-wrap mt-4 lg:hidden" v-if="showAcordos.indexOf(a.id) >= 0">
                    <div class="card-main" v-for="parcela in a.parcelas" :key="parcela.id">
                      <div class="card">
                        <div class="card_header">
                          <p class="text-sm font-bold">Parcela {{ parcela.numero }}</p>
                        </div>
                        <div class="card_body">
                          <div class="card_content">
                            <p>Prestaçao:</p>
                            <p>{{ parcela.prestacao | money }}</p>
                          </div>
                          <div class="card_content">
                            <p>Amortização:</p>
                            <p>{{ parcela.amortizacao | money }}</p>
                          </div>
                          <div class="card_content">
                            <p>Juros:</p>
                            <p>{{ parcela.juros | money }}</p>
                          </div>
                          <div class="card_content">
                            <p>Status:</p>
                            <p :class="parcela.status === 'Pago' ? 'text-green-500' : a.status == 'Cancelado' ? 'text-gray-700' :
                            parcela.status === 'Em Atraso' ? 'text-yellow-600' : 'text-gray-700'">{{
    a.status ==
      'Cancelado' ? a.status : parcela.status
  }}</p>
                          </div>
                          <div class="card_content">
                            <p>Vencimento:</p>
                            <p>{{ parcela.vencimentoUtil | moment('DD/MM/YYYY') }}</p>
                          </div>
                        </div>
                        <div class="flex flex-col md:flex-row justify-center"
                          v-if="a.status != 'Cancelado' && parcela.status != 'Pago' && parcela.status != 'Antecipada' && parcela.status != 'Antecipado' && parcela.status != 'Em acordo'">
                          <v-button class="flex justify-center md:mb-2 md:mb-0 md:mr-2" margin="mt-0 mb-2" size="sm"
                            :color="!(parcela.status == 'Em Atraso' && parcela.tomadorPodeGerarBoleto && parcela.urlPDFUltimoBoletoGerado) ? 'default' : 'primary'"
                            :disabled="!(parcela.status == 'Em Atraso' && parcela.tomadorPodeGerarBoleto && parcela.urlPDFUltimoBoletoGerado)"
                            @click="toogleModalSimulate(parcela.numero, emprestimo.id, a.id)">Simular Valor</v-button>
                          <v-button class="flex justify-center md:mb-2 md:mb-0" margin="mt-0 mb-2" size="sm"
                            :color="parcela.status === 'Pago' || !parcela.urlPDFUltimoBoletoGerado ? 'default' : 'secondary'"
                            :disabled="parcela.status === 'Pago' || !parcela.urlPDFUltimoBoletoGerado" @click="parcela.status === 'Em Atraso' && parcela.tomadorPodeGerarBoleto
                            ? toogleModalBoleto(parcela.numero, emprestimo.id, a.id)
                            : openBoleto(parcela.urlPDFUltimoBoletoGerado)">Gerar boleto</v-button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <p class="subtitle cursor-pointer" @click="emprestimo.showFluxo = !emprestimo.showFluxo">
                  <i style="font-size: 0.7em"
                    :class="`fas fa-${(emprestimo.showFluxo || !showAcordos.length ? 'minus' : 'plus')}`"></i>
                  Fluxo de pagamentos original
                </p>
                <div class="hidden lg:block w-full overflow-x-auto lg:overflow-x-hidden my-4 mt-2"
                  v-if="emprestimo.showFluxo || !showAcordos.length">
                  <vuetable ref="vuetable" class="solicitation_table" :api-mode="false" :fields="header"
                    :data="emprestimo.fluxoRepagamentos" :no-data-template="loading ? '' : 'Selecione um valor'">
                    <template v-slot:prestacao="props" class="flex items-center">{{
                      props.rowData.prestacao |
                        money
                    }}</template>
                    <template v-slot:juros="props" class="flex items-center">{{
                      props.rowData.juros | money
                    }}</template>
                    <template v-slot:amortizacao="props" class="flex items-center">{{
                      props.rowData.amortizacao |
                        money
                    }}</template>
                    <template v-slot:status="props" class="flex items-center">
                      <p :class="props.rowData.status === 'Pago' ? 'text-green-500' :
                      props.rowData.status === 'Em Atraso' ? 'text-yellow-600' : 'text-gray-700'">
                        {{ props.rowData.status }}</p>
                    </template>
                    <template v-slot:vencimentoUtil="props" class="flex items-center">{{
                      props.rowData.vencimentoUtil |
                        moment('DD/MM/YYYY')
                    }}</template>
                    <template v-slot:urlPDFUltimoBoletoGerado="props" class="flex items-center">
                      <i v-if="!props.rowData.urlPDFUltimoBoletoGerado && !props.rowData.urlPDFBoletoSRM"
                        class="fas fa-external-link-alt text-gray-400 cursor-not-allowed"></i>
                      <i v-else-if="props.rowData.status === 'Em Atraso' && props.rowData.tomadorPodeGerarBoleto && !props.rowData.urlPDFBoletoSRM"
                        class="fas fa-external-link-alt text-secondary cursor-pointer"
                        @click="toogleModalBoleto(props.rowData.numero, emprestimo.id)"></i>
                      <i v-else-if="props.rowData.status === 'Em Atraso' && !props.rowData.tomadorPodeGerarBoleto" &&
                        !props.rowData.urlPDFBoletoSRM class="fas fa-external-link-alt text-secondary cursor-pointer"
                        @click="openBoleto(props.rowData.urlPDFUltimoBoletoGerado)"></i>
                      <i v-else-if="props.rowData.status === 'A vencer' && !props.rowData.urlPDFBoletoSRM"
                        class="fas fa-external-link-alt text-secondary cursor-pointer"
                        @click="openBoleto(props.rowData.urlPDFUltimoBoletoGerado)"></i>
                      <a v-else-if="props.rowData.urlPDFBoletoSRM"
                        class="fas fa-external-link-alt text-secondary cursor-pointer"
                        :href="props.rowData.urlPDFBoletoSRM" target="_blank"></a>
                    </template>
                    <template v-slot:simulacao="props" class="flex items-center">
                      <p v-if="props.rowData.status == 'Em Atraso' && props.rowData.tomadorPodeGerarBoleto && props.rowData.urlPDFUltimoBoletoGerado"
                        @click="toogleModalSimulate(props.rowData.numero, emprestimo.id)"
                        class="text-secondary underline cursor-pointer">Simular</p>
                    </template>
                  </vuetable>
                </div>
                <div class="w-full flex flex-wrap mt-4 lg:hidden">
                  <div class="card-main" v-for="parcela in emprestimo.fluxoRepagamentos" :key="parcela.id">
                    <div class="card">
                      <div class="card_header">
                        <p class="text-sm font-bold">Parcela {{ parcela.numero }}</p>
                      </div>
                      <div class="card_body">
                        <div class="card_content">
                          <p>Prestaçao:</p>
                          <p>{{ parcela.prestacao | money }}</p>
                        </div>
                        <div class="card_content">
                          <p>Amortização:</p>
                          <p>{{ parcela.amortizacao | money }}</p>
                        </div>
                        <div class="card_content">
                          <p>Juros:</p>
                          <p>{{ parcela.juros | money }}</p>
                        </div>
                        <div class="card_content">
                          <p>Status:</p>
                          <p :class="parcela.status === 'Pago' ? 'text-green-500' :
                          parcela.status === 'Em Atraso' ? 'text-yellow-600' : 'text-gray-700'">{{ parcela.status }}
                          </p>
                        </div>
                        <div class="card_content">
                          <p>Vencimento:</p>
                          <p>{{ parcela.vencimentoUtil | moment('DD/MM/YYYY') }}</p>
                        </div>
                      </div>
                      <div class="flex flex-col md:flex-row justify-center"
                        v-if="parcela.status != 'Pago' && parcela.status != 'Antecipada' && parcela.status != 'Antecipado' && parcela.status != 'Em acordo'">
                        <v-button class="flex justify-center md:mb-2 md:mb-0 md:mr-2" margin="mt-0 mb-2" size="sm"
                          :color="!(parcela.status == 'Em Atraso' && parcela.tomadorPodeGerarBoleto && parcela.urlPDFUltimoBoletoGerado) ? 'default' : 'primary'"
                          :disabled="!(parcela.status == 'Em Atraso' && parcela.tomadorPodeGerarBoleto && parcela.urlPDFUltimoBoletoGerado)"
                          @click="toogleModalSimulate(parcela.numero, emprestimo.id)">Simular Valor</v-button>
                        <v-button class="flex justify-center md:mb-2 md:mb-0" margin="mt-0 mb-2" size="sm"
                          :color="parcela.status === 'Pago' || !parcela.urlPDFUltimoBoletoGerado ? 'default' : 'secondary'"
                          :disabled="parcela.status === 'Pago' || !parcela.urlPDFUltimoBoletoGerado" @click="parcela.status === 'Em Atraso' && parcela.tomadorPodeGerarBoleto
                          ? toogleModalBoleto(parcela.numero, emprestimo.id)
                          : openBoleto(parcela.urlPDFUltimoBoletoGerado)">Gerar boleto</v-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </tr>

        </table>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/Buttons/Button'
import SimulateModal from '@/components/Business/Modal/SimulateModal'
import GerarBoletoModal from '@/components/Business/Modal/GerarBoletoModal'
import Solicitation from '@/services/Business/Solicitation'
import MoneyFilter from '@/mixins/moneyFilter'
import Dashboard from '@/services/Business/Dashboard'
export default {
  name: 'solicitation',
  // props: ['solicitation'],
  components: { SimulateModal, GerarBoletoModal, VButton },
  mixins: [MoneyFilter],
  data() {
    return {
      showAcordos: [],
      loadingPage: false,
      showDocEmpresa: false,
      showModalSimulate: false,
      showModalBoleto: false,
      header: [
        {
          name: 'numero',
          title: 'Parcela'
        },
        {
          name: '__slot:prestacao',
          title: 'Prestação'
        },
        {
          name: '__slot:juros',
          title: 'Juros'
        },
        {
          name: '__slot:amortizacao',
          title: 'Armotização'
        },
        {
          name: '__slot:status',
          title: 'Status'
        },
        {
          name: '__slot:vencimentoUtil',
          title: 'Vencimento'
        },
        {
          name: '__slot:urlPDFUltimoBoletoGerado',
          title: 'Ver boleto'
        },
        {
          name: '__slot:simulacao',
          title: 'Simular Valor'
        }
      ],
      loading: false,
      solicitation: {},
      emprestimoId: 0,
      numeroParcela: 0,
      acordoId: 0,
    }
  },
  methods: {
    toggleAcordo(acordoId) {
      if (this.showAcordos.indexOf(acordoId) < 0) {
        this.showAcordos.push(acordoId);
      } else {
        this.showAcordos = this.showAcordos.filter(x => x != acordoId);
      }

      this.solicitation.emprestimos.map(x => x.showFluxo = false)
    },
    toogleModalSimulate(numeroParcela, emprestimoId, acordoId) {
      if (!acordoId)
        this.acordoId = 0;
      else
        this.acordoId = acordoId;
      this.emprestimoId = emprestimoId
      this.numeroParcela = numeroParcela
      this.showModalSimulate = true
    },
    toogleModalBoleto(numeroParcela, emprestimoId, acordoId) {
      if (!acordoId)
        this.acordoId = 0;
      else
        this.acordoId = acordoId;
      this.emprestimoId = emprestimoId
      this.numeroParcela = numeroParcela
      this.showModalBoleto = true
    },
    closeModal() {
      this.showModalSimulate = false
      this.showModalBoleto = false
      this.emprestimoId = 0
      this.numeroParcela = 0
    },
    openBoleto(url) {
      window.open(url, '_blank')
    }
  },
  async mounted() {
    try {
      const solicitations = (await Solicitation.getSolicitations()).data.data
      this.solicitation = solicitations.find(
        s => s.id === this.$route.params.id
      )

      console.log(this.solicitation)
      // this.emprestimo = this.solicitation.emprestimos[this.solicitation.emprestimos.length - 1]
      if (!this.solicitation) this.$router.push('/tomador/painel')
    } catch (error) {
      console.log(error)
    }
    try {
      const promises = this.solicitation.emprestimos.map(async emprestimo => {
        const response = (await Dashboard.getInfoInstallments(emprestimo.id))
          .data.data
        emprestimo = {
          ...emprestimo,
          showItem: false,
          showFluxo: false,
          fluxoRepagamentos: response,
          parcelasPagas: response.reduce(
            (acc, item) => (item.status === 'Pago' ? acc + 1 : acc),
            0
          ),
          parcelasRestantes: response.reduce(
            (acc, item) => (item.status !== 'Pago' ? acc + 1 : acc),
            0
          ),
          parcelasEmAtraso: response.reduce(
            (acc, item) => (item.status === 'Em Atraso' ? acc + 1 : acc),
            0
          ),
          acordos: (await Dashboard.getAcordos(emprestimo.id)).data.data
        }
        return emprestimo
      })
      this.solicitation.emprestimos = await Promise.all(promises)
      this.showAcordos = this.solicitation.emprestimos.map(x => x.acordos.filter(y => y.status == 'Ativo').map(x => x.id)).map(x => x[0])

      // const response = (await Dashboard.getInfoInstallments(this.emprestimo.id)).data.data
      // this.fluxoRepagamentos = response
      // this.emprestimo.parcelasPagas = response.reduce((acc, item) => item.status === 'Pago' ? acc + 1 : acc, 0)
      // this.emprestimo.parcelasRestantes = response.reduce((acc, item) => item.status !== 'Pago' ? acc + 1 : acc, 0)
      // this.emprestimo.parcelasEmAtraso = response.reduce((acc, item) => item.status === 'Em Atraso' ? acc + 1 : acc, 0)
    } catch (error) {
      console.log(error)
    }
  }
}
</script>

<style lang="sass" scoped>

.info-acordo
  font-size: 0.57em
  color: #494a4a

.subtitle
  padding: 0
  margin: 0
  color: #3c3d3d
  border-bottom: 0.5px solid #ccc
  @apply mt-3

.content_solicitation
  @apply w-full bg-white flex flex-col items-center p-6 border shadow rounded-lg

.title
  @apply font-bold text-xl text-primary text-center mt-4 mb-8
  @screen lg
    @apply text-2xl

.info_main
  @apply border border-secondary rounded w-full opacity-75 p-2
  background-color: rgba(72, 27, 144, 0.2)
  &_title
    @apply text-base text-secondary font-bold

.info
  @apply flex flex-col my-2 text-sm text-secondary w-full
  &_group
    strong
      @apply mr-1
    @apply flex

.button
  @apply rounded-full bg-white text-gray-600 text-sm shadow-md h-6 px-2
  &:focus
    @apply outline-none
  &_active
    @apply bg-secondary text-white
  &_calendar
    @apply rounded-full bg-white text-gray-600 text-sm shadow-md w-8 h-8 mr-3
    &:focus
      @apply outline-none
    &_active
      @apply bg-secondary text-white

.informations
  @apply w-full py-1 my-4 text-sm
  p:first-child
    @apply font-bold
  @screen lg
    @apply text-gray-600
  &_alert
    @apply w-full border-2 rounded-lg py-1 px-4 my-4 text-center cursor-pointer
    border-color: #CCBEA0
    background: #FFFAF3
    @screen lg
      @apply flex-row items-end
    &_title
      @apply text-sm font-bold text-left
      color: #7A4D05
      @screen lg
        @apply text-base

.card-main
  @apply w-full
  @screen md
    @apply w-1/2
    &:nth-child(odd)
      @apply pr-1
    &:nth-child(even)
      @apply pl-1
.card
  @apply w-full bg-white border shadow rounded-lg text-gray-600 mb-2
  &_header
    @apply flex flex-col items-center justify-center bg-gray-200 border-b border-gray-300
  &_body
    @apply p-2
  &_content
    @apply w-full flex text-sm
    p:first-child
      @apply font-bold mr-1
  &_footer
    @apply flex

// Tabela
table
  @apply w-full border-none

table tr
  @apply py-1
  margin-top: 100px
  &:focus
    @apply outline-none

table td
  @apply border-b border-primary flex items-center py-2 opacity-75 text-sm text-primary font-semibold uppercase cursor-pointer select-none
  @screen lg
    @apply text-xl normal-case

table td span
  @apply text-sm py-1 mr-3
  @screen lg
    @apply mr-6

.partner_name
  @apply font-bold opacity-75
  @screen lg
    @apply w-1/2

.spin
  animation: rotatey 2s linear infinite

@keyframes rotatey
  from
    transform: rotateY(0deg)
  to
    transform: rotateY(360deg)

</style>

<style lang="sass">

.solicitation_table
  @apply w-full bg-white shadow rounded-lg
  thead tr
    @apply rounded-t-lg
  tbody
    @apply rounded-b-lg
  tr
    &:nth-child(even)
      @apply bg-gray-100
    &:last-child
      @apply rounded-b-lg
      td:first-child
        @apply rounded-bl-lg
      td:last-child
        @apply rounded-br-lg
    th
      @apply p-2 text-sm text-gray-600
      background: #ECECEC
    td
      @apply relative text-gray-600 text-center text-sm border border-gray-300 py-0 px-2

</style>
