<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')" />
    <div class="container md:flex md:justify-center md:items-center">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <p class="title">Gerar boleto atualizado</p>
        <div class="flex w-full flex-col lg:flex-row">
          <v-input
            class="w-full"
            :options="valoresPermitidos"
            itemValue="value"
            itemText="label"
            name="value"
            v-model="value"
            :error="errors.first('value')"
            label="Selecione uma data para gerar o boleto*"
            v-validate="{required: true, is_not: -1}"
          ></v-input>
          <v-button class="flex justify-center mb-2 lg:mb-0" size="sm" @click="generateBoleto">Gerar boleto</v-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VInput from '@/components/Inputs/Input'
import VButton from '@/components/Buttons/Button'

import MoneyFilter from '@/mixins/moneyFilter'
import Dashboard from '@/services/Business/Dashboard'

export default {
  name: 'modal-gerar-boleto',
  components: { VInput, VButton },
  props: ['emprestimoId', 'numeroParcela','acordoId'],
  mixins: [MoneyFilter],
  data () {
    return {
      value: -1,
      loading: false,
      datasPermitidas: []
    }
  },
  async mounted () {
    try {
      this.datasPermitidas = (await Dashboard.datasPermitidas()).data.data
    } catch (error) {
      console.log(error)
    }
  },
  computed: {
    valoresPermitidos () {
      return this.datasPermitidas.map(v => ({
        label: this.$moment(v).format('DD-MM-YYYY'),
        value: v
      }))
    }
  },
  methods: {
    async generateBoleto () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        try {

          if(this.acordoId == 0){
            const response = (await Dashboard.gerarBoletoAtualizado({
              emprestimoId: this.emprestimoId,
              numeroParcela: this.numeroParcela,
              dataPagamento: this.value,
              enviarBoletoPorEmail: true
            })).data.data
          } else{
            const response = (await Dashboard.gerarBoletoAtualizadoAcordo({
              acordoId: this.acordoId,
              numeroParcela: this.numeroParcela,
              dataPagamento: this.value,
              enviarBoletoPorEmail: true
            })).data.data
         }
          this.$store.dispatch('notification', {
            type: 'success',
            message: 'Boleto gerado com sucesso!'
          })
          window.open(response.url, '_blank')
        } catch (error) {
          console.error(error)
          this.$store.dispatch('notification', {
            type: 'error',
            message: 'Houve algum erro. Tente novamente.'
          })
        } finally {
          this.$emit('closeModal')
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.modal
  @apply fixed flex w-screen h-full left-0 z-50
  @screen md
    @apply items-center top-0 justify-center

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full h-auto bg-white z-50 flex flex-col rounded-lg pt-4 px-4
  @screen lg
    @apply w-10/12

.title
  @apply text-secondary font-bold mb-2 text-center mt-2
  @screen lg
    @apply text-left text-lg

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.row
  @apply w-full flex flex-col
  @screen md
    @apply flex-row justify-center

.info_main
  @apply border border-secondary rounded w-full opacity-75 p-2
  background-color: rgba(72, 27, 144, 0.2)
  &_title
    @apply text-base text-secondary font-bold

.info
  @apply flex flex-col my-2 text-sm text-secondary w-full
  @screen md
    @apply flex-row
  &_left
    @apply w-full
    @screen md
      @apply w-1/2 pr-6
  &_right
    @apply w-full mt-2
    @screen md
      @apply w-1/2 pl-6 mt-0
  &_group
    @apply flex justify-between

.button
  @apply rounded-full bg-white text-gray-600 text-sm shadow-md h-6 px-2
  &:focus
    @apply outline-none
  &_active
    @apply bg-secondary text-white
  &_calendar
    @apply rounded-full bg-white text-gray-600 text-sm shadow-md w-8 h-8 mr-3
    &:focus
      @apply outline-none
    &_active
      @apply bg-secondary text-white

.informations
  @apply w-full py-1 my-4 text-sm
  p:first-child
    @apply font-bold
  @screen lg
    @apply text-gray-600
  &_alert
    @apply w-full border-2 rounded-lg py-1 px-4 my-4 text-center cursor-pointer
    border-color: #CCBEA0
    background: #FFFAF3
    @screen lg
      @apply flex-row items-end
    &_title
      @apply text-sm font-bold text-left
      color: #7A4D05
      @screen lg
        @apply text-base

::-webkit-scrollbar
  @screen md
    width: 8px
  @screen lg
    width: 8px

::-webkit-scrollbar-track
  @screen md
    @apply bg-gray-400 rounded-lg
  @screen lg
    @apply bg-gray-400 rounded-lg

::-webkit-scrollbar-thumb
  @screen md
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
  @screen lg
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
</style>

<style lang="sass">

.contribute_table
  @apply w-full bg-white shadow rounded-lg
  thead tr
    @apply rounded-t-lg
  tbody
    @apply rounded-b-lg
  tr
    &:nth-child(even)
      @apply bg-gray-100
    &:last-child
      @apply rounded-b-lg
      td:first-child
        @apply rounded-bl-lg
      td:last-child
        @apply rounded-br-lg
    th
      @apply p-2 text-sm text-gray-600
      background: #ECECEC
    td
      @apply relative text-gray-600 text-center text-sm border border-gray-300 py-0 px-2

</style>
